import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { BtnPrimary } from '~components/buttons/buttons.component';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './card.module.scss';

const Card = ({image, title, body, link, linkText, bynderImage, secured = false, customId}) => {
  const renderImg = () => {
    if (!image && !bynderImage) {
      return null;
    }

    if (bynderImage && bynderImage.length) {
      const {
        description,
        name,
        src,
      } = bynderImage[0];
      return (
        <img
          className={styles.cardImageResponsive}
          alt={description ? description : name}
          src={src}
        />
      );
    }

    if (secured) {
      const {
        description,
        file: { url },
      } = image.fields;

      return (
        <img
          className={styles.cardImageResponsive}
          alt={description}
          src={`${url}?fm=jpg&fl=progressive&w=550&fit=scale`}
        />
      );
    }

    const {
      description,
      fluid,
    } = image;

    return <Img alt={description} fluid={fluid} />;
  }

  const renderBody = () => {
    if (!body) {
      return null;
    }

    const text = secured ? body : body.body;

    return (
      <div className={styles.cardBody}>
        <RenderMarkdown text={text} />
      </div>
    );
  }

  return (
    <div className={styles.card} id={title}>
      <div className={`${styles.cardImage} hidden-mobile`}>
        {renderImg()}
      </div>
      <div className={styles.cardContent}>
        <h2 className={styles.cardTitle}>
          {link
            ? <Link to={link}>{title}</Link>
            : title}
        </h2>
        {renderBody()}
        {link &&
          <BtnPrimary className={styles.cardButton} path={link}>
            {linkText}
          </BtnPrimary>
        }
      </div>
    </div>
  );
};

export default Card;
