import React from 'react';
import Card from '~components/card/card.component';
import ResourceCard from '~components/resource-card/resource-card.component';

import styles from './card-list.module.scss';

export default ({ cards, secured = false }) => {
  if (!cards || !cards.length) {
    return null;
  }

  const renderCards = () => {
    return cards.map((card, i) => {
      const typename = !secured
        ? card.__typename
        : secured
          ? card.sys.contentType.sys.id
          : false;

      if (!typename) {
        return null;
      }

      switch (typename) {
        case "resourceCard":
        case 'ContentfulResourceCard':
          const resourceCard = secured ? card.fields : card;
          return (
            <ResourceCard
              key={i}
              {...resourceCard}
              secured={secured}
            />
          );

        case "contentCard":
        case 'ContentfulContentCard':
          const contentCard = secured ? card.fields : card;
          return (
            <Card
              key={i}
              {...contentCard}
              secured={secured}
            />
          );

        default:
          return null;
      }
    });
  }

  return (
    <div className={styles.cardList}>
      {renderCards()}
    </div>
  );
}
