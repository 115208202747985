import React from 'react';
import { BtnSecondary } from '~components/buttons/buttons.component';

import styles from './cta-featured.module.scss';

const CtaFeatured = props => {
  const {
    title,
    body,
    ctaText,
    ctaUrl,
  } = props;

  return (
    <div className={styles.ctaFeatured}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.body}>{body.body}</div>
      <BtnSecondary className={styles.button} path={`/${ctaUrl}/`}>
        {ctaText}
      </BtnSecondary>
    </div>
  )
};

export default CtaFeatured;
