import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { BtnPrimary } from '~components/buttons/buttons.component';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './featured-card.module.scss';

export default ({ image, title, body, link, linkText, bynderImage, secured = false }) => {
  const renderImg = () => {
    if (!image && !bynderImage) {
      return null;
    }

    if (bynderImage && bynderImage.length) {
      const {
        description,
        name,
        src,
      } = bynderImage[0];
      return (
        <img
          className={styles.featuredCardResponsive}
          alt={description ? description : name}
          src={src}
        />
      );
    }

    if (secured) {
      const {
        description,
        file: { url },
      } = image.fields;

      return (
        <img
          alt={description}
          src={url}
        />
      );
    }

    const {
      description,
      fluid,
    } = image;

    return <Img alt={description} fluid={fluid} />;
  }

  const renderBody = () => {
    if (!body) {
      return null;
    }

    const text = secured ? body : body.body;

    return (
      <div className={styles.featuredCardBody}>
        <RenderMarkdown text={text} />
      </div>
    );
  }

  return (
    <div className={styles.featuredCard}>
      {renderImg()}
      <div className={styles.featuredCardContent}>
        <h2 className={styles.featuredCardTitle}>
          <Link to={`/${link}/`}>{title}</Link>
        </h2>
        {renderBody()}
        <BtnPrimary path={`/${link}/`}>{linkText}</BtnPrimary>
      </div>
    </div>
  );
}
