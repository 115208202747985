import React from 'react';
import FeaturedCard from '~components/featured-card/featured-card.component';

import styles from './featured-card-list.module.scss';

const FeaturedCardList = ({ cards, secured = false }) => {
  if (!cards || !cards.length) {
    return null;
  }

  const renderCards = () => {
    return cards.map((card, i) => {
      if (secured) {
        return (
          <FeaturedCard
            key={i}
            {...card.fields}
            secured={secured}
          />
        );
      }
      return (
        <FeaturedCard
          key={i}
          {...card}
          secured={secured}
        />
      );
    });
  }

  return (
    <div className={styles.list}>
      {renderCards()}
    </div>
  );
}

export default FeaturedCardList;
